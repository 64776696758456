<template>
  <v-container class="white py-0 px-0" fluid>
    <v-card flat>
      <type-form :data="items" :group="typeGroup" @save="save" />
    </v-card>
  </v-container>
</template>

<script>
import TypeForm from "./TypeForm";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TypeEditor",
  components: {
    TypeForm,
  },
  data() {
    return {
      items: [],
      typeGroup: null,
    };
  },
  mounted() {
    this.typeGroup = this.getTypeGroup(this.routeParam);
    this.loadTypes(true).then(() => {
      this.items = this.getTypesByGroup(this.typeGroup.type);
      this.refreshBreadcrumb();
    });
  },
  computed: {
    ...mapGetters("types", ["getTypeGroup", "getTypesByGroup"]),

    routeParam() {
      return this.$route.params.route;
    },
  },
  methods: {
    ...mapActions("types", ["loadTypes", "saveType"]),
    // ...mapActions("ui", ["setBreadcrumbs"]),

    refreshBreadcrumb() {
      // const updatedBreadcrumbs = this.$route.meta.breadcrumbs.map((item) => {
      //   if (item.dynamic && this.typeGroup) {
      //     return {
      //       ...item,
      //       name: this.typeGroup.locale,
      //     };
      //   }
      //   return item;
      // });
      // this.setBreadcrumbs(updatedBreadcrumbs);
    },
    save({ group, items }) {
      if (this.notEmptyArray(items)) {
        this.saveType({ group, items }).then(() => {
          this.loadTypes();
        });
        this.$$router.back();
      }
    },
  },
};
</script>
